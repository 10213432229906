export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated.value === false) {
    if (process.client) {
      const toast = useToast()
      toast.add(
        {
            title: 'Please sign in to access this feature',
            actions: [{
              label: 'Sign in',
              click: () => {
                navigateTo('/auth/sign-in')
              }
            }],
            ui: {
                "progress": {
                    "base": 'absolute bottom-0 end-0 start-0 h-1',
                    "background": 'bg-brand dark:bg-brand',
                }
            },
        });
    }

    return abortNavigation(
      createError({
        statusCode: 403,
        message: 'The route is protected and requires authentication.',
      })
    );
  }

  const user = useUser();
  if (!user.value.is_onboarded && to.path !== '/app/onboarding') {
    return '/app/onboarding';
  }

  const app = useAppStore();
  await callOnce(app.init);
});